<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <!-- logo -->
        <v-card-title class="d-flex align-center justify-center py-7">
          <router-link to="/" class="d-flex align-center">
            <!--
            <v-img
              :src="require('@/assets/images/logos/logo.jpg')"
              max-height="50px"
              max-width="50px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

            -->

            <h2 class="text-2xl font-weight-semibold">
              Verif5 - Education Purpose
            </h2>
          </router-link>
        </v-card-title>

        <!-- title -->

        <!-- login form -->
        <v-card-text>
          <v-form name="form" id="check-login-form" @submit.prevent="handleLogin" ref="form" lazy-validation>
            <v-text-field v-model="user.username" outlined label="Username" hide-details class="mb-3"></v-text-field>

            <v-text-field v-model="user.password" outlined :type="isPasswordVisible ? 'text' : 'password'" label="Password" placeholder="············" :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" hide-details @click:append="isPasswordVisible = !isPasswordVisible"></v-text-field>

            <v-btn block color="primary" class="mt-6" form="check-login-form" type="submit">
              Login
            </v-btn>
          </v-form>
          <p v-if="message" style="color: red">{{message}}</p>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import User from '@/models/user'
import store from '@/store'

export default {
  setup() {
    const isPasswordVisible = ref(false)
    const email = ref('')
    const password = ref('')
    const socialLink = [
      {
        icon: mdiFacebook,
        color: '#4267b2',
        colorInDark: '#4267b2'
      },
      {
        icon: mdiTwitter,
        color: '#1da1f2',
        colorInDark: '#1da1f2'
      },
      {
        icon: mdiGithub,
        color: '#272727',
        colorInDark: '#fff'
      },
      {
        icon: mdiGoogle,
        color: '#db4437',
        colorInDark: '#db4437'
      }
    ]

    return {
      isPasswordVisible,
      email,
      password,
      socialLink,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline
      }
    }
  },
  data() {
    return {
      user: new User('', ''),
      loading: false,
      message: ''
    }
  },
  computed: {
    loggedIn() {
      return store.state.auth.status.loggedIn
    }
  },
  created() {
    if (this.loggedIn) {
      let roles =
        localStorage.getItem('user') == null
          ? null
          : JSON.parse(localStorage.getItem('user')).roles
      if (roles.includes('ROLE_SUPER') || roles.includes('ROLE_CLERK')) {
        this.$router.push({ name: 'dashboard' })
      } else {
        this.$router.push('/auth/login')
      }
    }
  },
  methods: {
    handleLogin() {
      this.loading = true
      if (!this.$refs.form.validate()) return

      if (this.user.username && this.user.password) {
        store.dispatch('auth/login', this.user).then(
          success => {
            if (success.is2Fa) {
              store.state.auth.status.loggedIn = false
              this.$router.push({
                name: 'Login2Fa',
                params: {
                  username: this.user.username,
                  password: this.user.password
                }
              })
            } else {
              let roles = JSON.parse(localStorage.getItem('user')).roles
              this.$router.push({ name: 'dashboard' })
            }
          },
          error => {
            this.loading = false
            this.message = error.response.data
            //alert(JSON.stringify(this.message));
          }
        )
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
